// Classes
import BaseService from './base-service';

// Utils
import { cloneDeep, compact, get, isNil, isArray, isEmpty, isObject, map, toPairs, omit } from 'lodash';
import dictree from 'dictree';
import { sortByOrder } from 'libs/utils/collections';
import { humanizeType } from 'libs/utils/string';
import { getBackstageURL } from 'libs/utils/url';
import { getEventSupportedLocalesForKindOptions } from 'libs/utils/locales';

/**
 * @const {String} METADATA_API_ENDPOINT the metadata API endpoint. Interpolations: `{{eventId}}`.
 * @private
 */
const METADATA_API_ENDPOINT = '/api/v1/events/{{eventId}}/metadata';

/**
 * @const {String} METADATA_COMPILE_API_ENDPOINT the metadata compilation API endpoint. Interpolations: `{{eventId}}`.
 * @private
 */
const METADATA_COMPILE_API_ENDPOINT = '/api/v1/eid/{{eventId}}/compile/metadata';

/**
 * @constant {String} FP_TYPE_LISTING_PATH FP Type specific backstage path. Interpolations: `{{eventId}}`, `{{fpType}}`.
 * @private
 */
export const FP_TYPE_LISTING_PATH = '/event/{{eventId}}/{{fpType}}s';

/**
 * @constant {String} FETCH_METADATA_BIT_ENDPOINT Interpolations: `{{eventId}}`, `{{fpType}}`.
 * @private
 */
const FETCH_METADATA_BIT_ENDPOINT = '/api/v1/events/{{eventId}}/docbyid/bstg-metadata-bit-{{fpType}}';

/**
 * @constant {String} SAVE_METADATA_BIT_ENDPOINT Interpolations: `{{eventId}}`.
 * @private
 */
const SAVE_METADATA_BIT_ENDPOINT = '/api/v1/eid/{{eventId}}/data/metadata-bit';

const OVERRIDE_METADATA_BIT_ID_PREFIX = 'bstg-metadata-bit-';
const OVERRIDE_METADATA_BIT_PRIORITY = 'highest';
const OVERRIDE_METADATA_BIT_OWNER = 'private';
const OVERRIDE_METADATA_FIELDS_TO_OMIT = [
    'field',
    'selectedChoice',
    'toBeDeleted',
    'isDeleted',
    'addKind',
    'dirty',
    'type'
];

/**
 * @constant {(name: string, label: string, kind?: string)[]} REGISTRATION_DEFAULT_FIELDS The default registration fields.
 */
const REGISTRATION_DEFAULT_FIELDS = [
    {
        name: 'email',
        kind: 'email',
        label: 'Email address',
        removable: false,
        required: true
    },
    {
        name: 'fname',
        label: 'First name',
        removable: false,
        required: true
    },
    {
        name: 'lname',
        label: 'Last name',
        removable: false,
        required: true
    },
    { name: 'city', label: 'City', removable: true },
    { name: 'company', label: 'Company', removable: true },
    { name: 'country', label: 'Country', kind: 'choice-list', removable: true },
    { name: 'linkedin_url', label: 'LinkedIn', removable: true },
    { name: 'phone', label: 'Phone', kind: 'phone', removable: true },
    { name: 'position', label: 'Job', removable: true },
    { name: 'website', label: 'Website', removable: true }
];

/** @const {string[]} PUBLIC_FORBIDDEN_KINDS list of publicly forbidden kinds */
const PUBLIC_FORBIDDEN_KINDS = [
    'custom',
    'display-only',
    'file',
    'i18n',
    'nested-object',
    'password',
    'video-call'
];

/** @const {RegExp[]} PUBLIC_FORBIDDEN_FIELDS list of publicly forbidden fields */
const PUBLIC_FORBIDDEN_FIELDS = [/^fp_.*/, /^_.*/];

/** @const {RegExp[]} PUBLIC_ALLOWED_FIELDS list of publicly allowed fields */
const PUBLIC_ALLOWED_FIELDS = ['_registered_sessions', 'fp_status'];

/**
 * @constant {string[]} REGISTRATION_ALLOWED_KINDS The kinds allowed for the registration fields
 */
const REGISTRATION_ALLOWED_KINDS = [
    'boolean', // Checkbox
    'choice-list', // Select
    'choice', // Radio
    'email', // Text
    'formatted-datetime', // Datetime picker
    'hidden', // Hidden
    'locale', // Select
    'number', // Text
    'text-multiline', // Textarea
    'text', // Text,
];

const ALL_KINDS = [
    'auto',
    'boolean',
    'choice-list',
    'choice',
    'colour',
    'content-page',
    'display-only',
    'email',
    'embed',
    'external',
    'formatted-datetime',
    'hidden',
    'html',
    'list',
    'nested-object',
    'number',
    'password',
    'text-multiline',
    'text',
    'timestamp',
    'video-call'
];

const REPRESENTATIONS = [
    '_representation',
    '_representation_line2',
    '_representation_line3',
    '_representation_image',
];

const INVALID_FIELD_NAMES = [
    '_id',
    'id',
    'is_team_member',
];

const FIELDS_THAT_MAY_NOT_BE_PRIVATE = [
    'mailertarget1',
    'mailertarget2',
    'mailertarget3',
];

/**
 * Provides a facility to handle metadata records.
 *
 * @example
 * import MetadataService from 'libs/services/metadata';
 * ...
 * const metadata = new MetadataService();
 */
export default class MetadataService extends BaseService {

    constructor() {
        super();

        this.__METADATA = {};
    }


    /**
     * Constant representing all kinds.
     * @readonly
     * @type {Array<string>}
     */
    get ALL_KINDS() {
        return ALL_KINDS;
    }

    /**
     * Constant representing all representation properties.
     * @readonly
     * @type {Array<string>}
     */
    get REPRESENTATIONS() {
        return REPRESENTATIONS;
    }

    /**
     * Array of supported targeting kinds.
     *
     * @type {Array<string>}
     */
    get TARGETING_SUPPORTED_KINDS() {
        return ['boolean', 'choice', 'choice-list', 'email', 'hidden', 'locale', 'text'];
    }

    /**
     * Asks the server to compile a fresh version of the metadata
     *
     * @param {String} eventId the ID of the event to compile the metadata for
     * @param {Boolean} [dryRun=false] whether to perform a dry run or not
     *
     * @return {Promise<import('axios').AxiosResponse>} the server response
     */
    compileMetadata(eventId, dryRun = false) {
        const method = dryRun ? 'get' : 'post';
        const url = METADATA_COMPILE_API_ENDPOINT.replace('{{eventId}}', eventId);

        return this[method](url);
    }

    /**
     * Gets the metadata for the given event.
     *
     * NOTE: This method could return a cached value, if you need a fresh value, please
     * use the `forceUpdate` flag.
     *
     * @param {string|Workspace} eventOrEventId the event ID or the event object
     * @param {Object} args optional arguments
     * @param {string} [args.eventNode] the node of the event
     * @param {Boolean} [args.forceUpdate] force an update of the metadata
     * @param {Boolean} [args.skipCompile] skip the compilation (only relevant if the update is forced/done)
     *
     * @return {Promise<Object>}
     */
    async getMetadata(eventOrEventId, { eventNode, forceUpdate, skipCompile } = {}) {
        let eventId;

        if (typeof eventOrEventId === 'string') {
            eventId = eventOrEventId;
        }

        if (isObject(eventOrEventId)) {
            eventNode = eventOrEventId.node;
            eventId = eventOrEventId._id || eventOrEventId.id;
        }

        const cachedMetadata = this.getCachedMetadata(eventId);

        if (!forceUpdate && cachedMetadata) {
            return cachedMetadata;

        } else {

            if (!skipCompile) {
                try {
                    await this.compileMetadata(eventId);
                } catch (error) {
                    console.error('[MetadataService] An error occurred while compiling the metadata', error.message);
                }
            }

            let metadata;

            try {
                const path = METADATA_API_ENDPOINT.replace('{{eventId}}', eventId);
                const url = eventNode ? getBackstageURL(eventNode, path) : path;
                const { data } = await this.get(url, { withCredentials: true });
                metadata = data;

            } catch (error) {
                console.error('[MetadataService] An error occurred while fetching metadata', error.message);
            }

            if (!isObject(metadata)) {
                metadata = { _id: 'metadata' };
            }

            this.__METADATA[eventId] = metadata;

            return metadata;
        }
    }

    /**
     * Given an event ID this method return its cached metadata
     *
     * @param {String} eventId the ID of the event to get the metadata for
     *
     * @return {Object} the event's metadata
     */
    getCachedMetadata(eventId) {
        return this.__METADATA[eventId];
    }

    /**
     * Gets a cached version of the given FP Type
     *
     * @param {String} eventId the ID of the event
     * @param {String} fpType the fp type to get the metadata for
     * @param {Boolean} [mergeDynamicExtensions=false] if true it tries to merge dynamic extensions
     * @param {Boolean} [filterPrivate=false] if true it will strip all the private fields
     * @param {string[]} [filterPrivateExceptions] exceptions to filterPrivate
     *
     * @return {Object} the cached metadata for the given FP type
     */
    getCachedMetadataForType(eventId, fpType, mergeDynamicExtensions = false, filterPrivate = false, filterPrivateExceptions = []) {
        const cachedMetadata = this.getCachedMetadata(eventId);

        if (!cachedMetadata || !cachedMetadata[fpType]) {
            return {};
        }

        const base = cloneDeep(cachedMetadata[fpType] || {});

        // dynamic extensions mergey surgery
        if (mergeDynamicExtensions) {
            const dynamicExtFields = cachedMetadata[fpType]._dynamic_ext || {};

            Object.keys(dynamicExtFields).forEach(field => {
                if (!isObject(dynamicExtFields[field])) return;
                dynamicExtFields[field].is_dynamic_ext = true;
                base[`_${field}`] = dynamicExtFields[field];
            });

            delete base._dynamic_ext;
        }

        for (const [key, value] of Object.entries(base)) {
            if (isNil(key) || isNil(value)) {
                delete base[key];
                continue;
            }

            if (filterPrivate && !filterPrivateExceptions.includes(key) && !this.isFieldPublic(key, value)) {
                delete base[key];
            }

            if (isObject(value)) {
                value.name = value.name || key;
            }
        }

        return base;
    }

    /**
     * Checks if the given field can be used for public operations
     *
     * @param {string} fieldName the name of the metadata field
     * @param {object} fieldDefinition the definition of the field
     *
     * @returns {boolean} whether the field can be used publicly
     */
    isFieldPublic(fieldName, fieldDefinition) {
        if (PUBLIC_ALLOWED_FIELDS.includes(fieldName)) {
            return true;
        }

        if (
            PUBLIC_FORBIDDEN_FIELDS.some(reg => reg.test(fieldName)) ||
            (isObject(fieldDefinition) && PUBLIC_FORBIDDEN_KINDS.some(kind => fieldDefinition.kind === kind))
        ) {
            return false;
        }

        return true;
    }

    /**
     * Gets a cached or fresh version of the given FP Type
     *
     * @param {String} eventId the ID of the event
     * @param {String} fpType the fp type to get the metadata for
     * @param {Boolean} [mergeDynamicExtensions=false] if true it tries to merge dynamic extensions
     * @param {Boolean} [filterPrivate=false] if true it will strip all the private fields
     * @param {string[]} [filterPrivateExceptions] exceptions to filterPrivate
     *
     * @return {Promise<Object>} the metadata for the given FP type
     */
    async getMetadataForType(eventId, fpType, mergeDynamicExtensions = false, filterPrivate = false, filterPrivateExceptions = []) {
        await this.getMetadata(eventId);
        return this.getCachedMetadataForType(eventId, fpType, mergeDynamicExtensions, filterPrivate, filterPrivateExceptions);
    }

    /**
     * Gets a cached version of the given FP Type in a form of an Array
     *
     * @param {String} eventId the ID of the event
     * @param {String} fpType the fp type to get the metadata for
     * @param {Boolean} [mergeDynamicExtensions=false] if true it tries to merge dynamic extensions
     *
     * @return {Array} a compacted version of the metadata fields
     */
    getCachedMetadataForTypeAsArray(eventId, fpType, mergeDynamicExtensions = false) {
        const mdHash = this.getCachedMetadataForType(eventId, fpType, mergeDynamicExtensions);

        return this.convertMetadataHashToOrderedList(mdHash);
    }

    /**
     * Finds all the fields that yield the specified flag.
     * Note that `flagPath` can specify a nested field.
     *
     * @param {Object[]} fieldsList an array of field descriptors
     * @param {String} flagPath object field path (dot notation)
     *
     * @return {String[]} a list of fields names that matches the flag
     */
    findFieldsWithFlag(fieldsList, flagPath) {
        let fields = fieldsList.filter(field => get(field, flagPath));
        fields = compact(fields);
        return fields = map(fields, 'field');
    }

    /**
     * Converts the metadata object into an ordered list of fields
     *
     * @param {FieldDescriptor|FieldDescriptor[]} mdHash the metadata hash
     *
     * @return {Array} a compacted version of the metadata fields
     */
    convertMetadataHashToOrderedList(mdHash) {
        if (!isObject(mdHash)) {
            return [];
        }
        if (isArray(mdHash)) {
            return mdHash;
        }

        const mdList = compact(
            toPairs(mdHash)
                .map(pair => {
                    // omit null and special fields
                    // @ts-ignore
                    if (!isObject(pair[1]) || (pair[0].charAt(0) === '_' && !pair[1].is_dynamic_ext)) {
                        return null;
                    }
                    return Object.assign(pair[1], { field: pair[0] });
                })
        );

        return sortByOrder(mdList, 'label');
    }

    /**
     * Given an FP type and and Event ID, this method returns the path of
     * the page where we list that type of documents.
     *
     * @param {String} eventId the ID of the event
     * @param {String} fpType the desired FP type
     *
     * @return {String} the path of the page where we list the given FP type
     */
    getFpTypeListingPath(eventId, fpType) {
        return this.buildUrl(FP_TYPE_LISTING_PATH, { eventId, fpType });
    }

    /**
     * Compiles a list of available fields to be eventually added
     * to the event's registration page
     *
     * @param {string} [eventId] the ID of the event
     * @param {boolean} [onlyPublic] to filter out the private fields
     *
     * @returns {Promise<Object[]>} a list of fields for the event's registration page
     */
    async getUserRegistrationFields(eventId, onlyPublic = false) {
        if (!eventId) {
            return REGISTRATION_DEFAULT_FIELDS;
        }

        const metadata = await this.getCachedMetadataForType(eventId, 'person');
        const descriptors = this.pickDescriptorsByKind(metadata, REGISTRATION_ALLOWED_KINDS);
        const fields = [];

        for (const desc of sortByOrder(descriptors)) {
            desc.removable = !get(desc, 'validations.required', false);
            const field = omit(desc, [
                '_bulk_edit',
                '_editable',
                'crypt',
                'example',
                'order',
                'searchable',
                'sortable',
                'validations',
            ]);

            if (['position', 'company'].includes(field.name)) {
                field._default = true;
            }

            if (!onlyPublic || onlyPublic && !desc.crypt) {
                // crypt tells us if the field is private
                fields.push(field);
            }
        }

        return fields;
    }

    /**
     * Picks the public fields of the specified fp_type'd metadata based
     * on the kinds argument
     *
     * @param {FieldDescriptor[]} fpTypedMetadata the metadata of a specific fp_type
     * @param {string[]} [kinds] an array of strings representing the valid kinds
     *
     * @returns {object[]} a list that contains the speficied kinds for the give fp_type metadata
     *
     * @private
     */
    pickDescriptorsByKind(fpTypedMetadata, kinds = []) {
        const descriptors = [];
        for (const [key, value] of Object.entries(fpTypedMetadata)) {
            if (key.startsWith('_') || key.startsWith('fp_') || !isObject(value)) {
                continue;
            }

            value.kind = value.kind || 'text';
            if (kinds.length && !kinds.includes(value.kind)) {
                continue;
            }

            value.name = key;
            descriptors.push(value);
        }

        return descriptors;
    }

    /**
     * Returns a validation schema for a given fp_type
     *
     * @param {string} eventId the ID of the event
     * @param {string} fpType the fp type to get the validation schema for
     *
     * @returns {Promise<object>} the validation schema
     *
     * @see https://ajv.js.org/json-schema.html
     */
    async getSchemaForType(eventId, fpType) {
        try {
            if (isEmpty(this.getCachedMetadataForType(eventId, fpType))) {
                await this.getMetadata(eventId);
            }

            const meta = this.getCachedMetadataForType(eventId, fpType, true);
            const metadata = this.getCachedMetadataForTypeAsArray(eventId, fpType);
            const required = [];
            const schema = {
                title: meta._type_representation || fpType,
                description: meta._description,
                $comment: meta._description,
                properties: {},
                required
            };

            const kindToJsonSchemaType = field => {
                switch (field.kind) {
                    case 'timestamp':
                        return ['number'];

                    case 'external':
                        return ['null', field.kind_options.single_doc ? 'string' : 'array', 'object'];

                    case 'nested-object':
                        return ['object'];

                    case 'list':
                        return ['array'];

                    case 'boolean':
                        return ['boolean'];

                    case 'number':
                        return ['number'];

                    case 'text':
                    case 'text-multiline':
                    case 'colour':
                    case 'email':
                    case 'html':
                    case 'password':
                    case 'choice':
                    case 'choice-list':
                        return ['string'];

                    case undefined:
                        return ['string'];

                    default:
                        return null;
                }
            };

            metadata.forEach(field => {
                const key = field.field;
                if (key.startsWith('_')) return;

                const type = kindToJsonSchemaType(field);
                if (!type) return;

                schema.properties[key] = {
                    type
                };

                if (field.example) {
                    schema.properties[key].examples = [field.example];
                }

                const isRequired = field?.validations?.required;
                if (isRequired) {
                    schema.required = schema.required || [];
                    schema.required.push(key);
                }
            });

            return schema;
        } catch (error) {
            console.warn('[MetadataService] Could not determine the validation schema', error.message);
            return {};
        }
    }

    /**
     * Retrieves the targeting metadata for an event.
     *
     * @param {Event} event - The event object.
     * @param {boolean} [includeEmptyState] - Whether to include empty state metadata.
     * @param {object} [emptyState] - The empty state metadata object.
     *
     * @returns {Promise<object>} - The targeting metadata.
     */
    async getTargetingMetadata(event, includeEmptyState = true, emptyState = { value: '*has_field*', label: 'None' }) {
        return await this.getDecoratedMetadataForType(event, 'person', {
            mergeDynamicExtensions: true,
            filterPrivate: true,
            filterPrivateExceptions: ['fp_rsvp_status', 'fp_locale'],
            includeEmptyState,
            emptyState
        });
    }

    /**
     * Gets the decorated metadata for a specific event and fp type.
     *
     * @param {Object} event - The event object.
     * @param {string} fpType - The file path type.
     * @param {Object} options - The options for decorating the metadata.
     * @param {boolean} [options.mergeDynamicExtensions=false] - Whether to merge dynamic extensions.
     * @param {boolean} [options.filterPrivate=false] - Whether to filter private metadata.
     * @param {Array} [options.filterPrivateExceptions=[]] - The exceptions to the private metadata filter.
     * @param {boolean} [options.includeEmptyState=false] - Whether to include empty state.
     * @param {Object} [options.emptyState={ value: '', label: 'None' }] - The empty state object.
     *
     * @returns {Promise<Object>} - The decorated metadata.
     */
    async getDecoratedMetadataForType(event, fpType, {
        mergeDynamicExtensions,
        filterPrivate,
        filterPrivateExceptions,
        includeEmptyState,
        emptyState
    } = {
        mergeDynamicExtensions: false,
        filterPrivate: false,
        filterPrivateExceptions: [],
        includeEmptyState: false,
        emptyState: { value: '', label: 'None' }
    }) {
        const metadata = await this.getMetadataForType(event._id, fpType, mergeDynamicExtensions, filterPrivate, filterPrivateExceptions);
        this.decorateLocaleFields(event, metadata, includeEmptyState, emptyState);
        return metadata;
    }

    /**
     * Decorates locale fields in the metadata object with supported locales for a given event.
     *
     * @param {Object} event - The event object.
     * @param {Array|Object} metadata - The metadata object containing fields.
     * @param {boolean} [includeEmptyState=false] - Flag indicating whether to include an empty state option.
     * @param {Object} [emptyState={ value: '', label: 'None' }] - The empty state option object.
     */
    decorateLocaleFields(event, metadata, includeEmptyState = false, emptyState = { value: '', label: 'None' }) {
        const fields = isArray(metadata) ? metadata : Object.values(metadata);
        for (const field of fields) {
            if (field.kind === 'locale') {
                field.kind_options = {
                    values: getEventSupportedLocalesForKindOptions(event, includeEmptyState, emptyState)
                };
            }
        }
    }

    /**
     * Filters duplicate targets or exceptions fields from the given list.
     * Only allows one targets or exceptions field to pass through because the single widget caters for both.
     *
     * @param {Array} fieldsList - The list of fields to filter.
     *
     * @returns {Array} - The filtered list of fields.
     */
    filterDuplicateTargetsExceptionsFields(fieldsList) {
        // only let one targets or exceptions filter through because the single widget caters for both
        let _isFirstTargetsExceptionsField = true;

        return fieldsList.filter(descriptor => {
            if (descriptor.kind !== 'external' || descriptor.kind_options && descriptor.kind_options.type !== 'targets-exceptions') {
                return true;
            }
            const ret = _isFirstTargetsExceptionsField;

            _isFirstTargetsExceptionsField = false;
            return ret;
        });
    }

    /**
     * Take a given metadata object and returns the list of all types that can be configured/used.
     * @param {Object} metadata
     * @param {Object} args
     * @param {boolean} [args.hideForMetadata]
     * @param {boolean} [args.hideForPicker]
     * @returns {{id:string , label:string}[]}
     */
    getMetadataTypeList(metadata, { hideForMetadata = false, hideForPicker = false }) {
        const types = [];
        for (const type in metadata) {
            const typeMetadata = metadata[type];
            if (!isObject(typeMetadata) || hideForMetadata && typeMetadata._hide_from_metadata_editor || hideForPicker && typeMetadata._hide_from_fp_picker) {
                continue;
            }
            const label = humanizeType(typeMetadata, type, true);
            // Empty _type_representation are not to be used
            if (label) {
                types.push({ id: type, label });
            }
        }
        return types;
    }

    /**
     * Checks if a specific feature is available for a given event and file path type.
     *
     * @param {string} eventId - The ID of the event.
     * @param {string} fpType - The file path type.
     * @param {string} feature - The feature to check availability for.yp
     * @param {boolean} mergeDynamicExtensions - Whether to merge dynamic extensions.
     *
     * @returns {boolean} - Returns true if the fpType is bulk editable, otherwise false.
     */
    isFeatureForTypeAvailable(eventId, fpType, feature, mergeDynamicExtensions) {
        const metadata = this.getCachedMetadataForType(eventId, fpType, mergeDynamicExtensions);
        return metadata.hasOwnProperty(`_${feature}`);
    }

    /**
     * Checks if the given fpType is editable for the specified event.
     *
     * @param {string} eventId - The ID of the event.
     * @param {string} fpType - The type of the fp (file path).
     * @param {boolean} mergeDynamicExtensions - Whether to merge dynamic extensions.
     *
     * @returns {boolean} - Returns true if the fpType is bulk editable, otherwise false.
     */
    isFpTypeDuplicable(eventId, fpType, mergeDynamicExtensions) {
        return this.isFeatureForTypeAvailable(eventId, fpType, 'duplicate', mergeDynamicExtensions);
    }

    /**
     * Checks if the given fpType is bulk editable for the specified event.
     *
     * @param {string} eventId - The ID of the event.
     * @param {string} fpType - The type of the fp (file path).
     * @param {boolean} mergeDynamicExtensions - Whether to merge dynamic extensions.
     *
     * @returns {boolean} - Returns true if the fpType is bulk editable, otherwise false.
     */
    isFpTypeBulkEditable(eventId, fpType, mergeDynamicExtensions) {
        return this.isFeatureForTypeAvailable(eventId, fpType, 'bulk_edit', mergeDynamicExtensions);
    }

    /**
     * Determines if the given event and field type are time shiftable.
     *
     * @param {string} eventId - The ID of the event.
     * @param {string} fpType - The field type.
     * @param {boolean} mergeDynamicExtensions - Whether to merge dynamic extensions.
     *
     * @returns {boolean} - True if the field type is time shiftable, false otherwise.
     */
    isFpTypeTimeShiftable(eventId, fpType, mergeDynamicExtensions) {
        const metadata = this.getCachedMetadataForTypeAsArray(eventId, fpType, mergeDynamicExtensions);
        const timeShiftableFields = metadata.filter(f => f._timeshift);

        return timeShiftableFields.length > 0;
    }


    /** @type {(fieldName: string) => boolean} */
    isValidFieldName(fieldName) {
        return !(
            INVALID_FIELD_NAMES.includes(fieldName)
            || fieldName.startsWith('fp_')
            || fieldName.startsWith('_')
            || !isNaN(parseInt(fieldName[0], 10))
            || !/^[\w-]*$/.test(fieldName)
        );
    }

    /** @type {(fieldName: string) => boolean} */
    shouldNotBePrivate(fieldName) {
        return FIELDS_THAT_MAY_NOT_BE_PRIVATE.includes(fieldName);
    }

    /**
     * @param {string} eventId
     * @param {string} fpType
     */
    async getMetadataBitByFpType(eventId, fpType) {
        const url = FETCH_METADATA_BIT_ENDPOINT.replace('{{eventId}}', eventId).replace('{{fpType}}', fpType);
        const { data } = await this.get(url, { withCredentials: true });
        return data;
    }

    /**
     * @param {string} eventId
     * @param {Record<string,unknown>} bitDoc
     */
    async saveMetadataBit(eventId, bitDoc) {
        const url = SAVE_METADATA_BIT_ENDPOINT.replace('{{eventId}}', eventId);
        await this.post(
            url,
            { rows: [bitDoc] },
            {
                withCredentials: true,
                params: { keep_id: true, pretty_field_name: true },
            },
        );
    }

    /**
     * @param {string} eventId
     * @param {string} fpType
     * @param {Record<string,unknown>} type
     * @param {Record<string,unknown>} existingMetadata
     * @param {Record<string,unknown>} existingOverrideBit
     * @param {Record<string,string>} representations
     * @returns {Record<string,unknown>}
     */
    getOverrideBitFromDiff(
        eventId,
        fpType,
        type,
        existingMetadata,
        existingOverrideBit,
        representations,
    ) {
        const _id = `${OVERRIDE_METADATA_BIT_ID_PREFIX}${fpType}`;
        const baseOverrideBit = {
            _id,
            fp_ext_id: _id,
            fp_bit_priority: OVERRIDE_METADATA_BIT_PRIORITY,
            fp_owner: OVERRIDE_METADATA_BIT_OWNER
        };

        if (!isObject(existingMetadata[fpType])) {
            return baseOverrideBit;
        }

        const bitFpType = {};
        const addedFields = [];
        Object.entries(type)
            .sort((a, b) => a[1].order > b[1].order ? 1 : -1)
            .forEach(([field, definition], index) => {
                if (definition.toBeDeleted) {
                    // deleted fields are set as null
                    bitFpType[field] = null;
                } else if (isObject(definition)) {
                    // omit fields and set order as index
                    bitFpType[field] = omit(
                        { ...definition, order: index },
                        OVERRIDE_METADATA_FIELDS_TO_OMIT,
                    );
                }
                addedFields.push(field);
            });

        // add nulls back into the bit manually. this avoids rogue prunes when values disappear on subsequent runs
        for (const [field, definition] of Object.entries(existingMetadata[fpType] || {})) {
            if (definition === null && !addedFields.includes(field)) {
                bitFpType[field] = null;
            }
        }

        const newFullOverrideBit = {
            [fpType]: { ...bitFpType, ...representations },
        };

        // don't save an empty hash that might make the bit-compiler blat everything
        if (isEmpty(newFullOverrideBit[fpType])) { delete newFullOverrideBit[fpType]; }

        // diff between new and existing removing keys starting with _ that are not representations
        const fieldsChangesDict = cloneDeep(dictree.diff(newFullOverrideBit[fpType], existingMetadata[fpType] || {}));
        for (const key of Object.keys(fieldsChangesDict)) {
            if (key.startsWith('_') && !REPRESENTATIONS.includes(key)) {
                delete fieldsChangesDict[key];
            }
        }

        const fullChangesDict = {
            ...baseOverrideBit,
            [fpType]: fieldsChangesDict,
        };

        // the diffed object cannot be null or contain an empty object. this will break stuff!
        // putting something here will prevent a wipe of the metadata if everything ends up being the same between overrides when diffed!
        // unfortunately this is necessary to get around the crappiness of this situation
        if (isObject(fullChangesDict[fpType]) && isEmpty(fullChangesDict[fpType])) {
            fullChangesDict[fpType]._ignore_this = true;
        }

        /**
         * Removes fields that should not be kept
         * @param {Record<string,unknown>} dict
         */
        const cleanUpDict = dict => {
            for (const [field, definition] of Object.entries(dict)) {
                if (definition) {
                    delete dict[field]._registrations;
                    if (addedFields.includes(field)) {
                        delete dict[field]._to_prune;
                    }
                    if (isObject(definition.kind_options)) {
                        // never prune kind_options, this is not needed and will break stuff
                        delete dict[field].kind_options._to_prune;
                    }
                }
            }
        };

        // ABORT with changes dict if no existing override (create one)
        if (isEmpty(existingOverrideBit)) {
            cleanUpDict(fullChangesDict[fpType]);
            return fullChangesDict;
        }

        const mergedChanges = cloneDeep(dictree.merge([existingOverrideBit, fullChangesDict]), existingOverrideBit);
        // This method is NOT compatible with dynamic extensions, and will simply ignore them
        cleanUpDict(mergedChanges[fpType]);
        return mergedChanges;
    }

    /**
     * @param {string} eventId
     * @param {string} fpType
     * @param {Record<string,unknown} metadataByType
     */
    validateMetadataUpdate(eventId, fpType, metadataByType) {
        const original = this.getCachedMetadataForType(eventId, fpType);
        const originalFields = Object.keys(original);
        const newFields = Object.keys(metadataByType).filter(d => !originalFields.includes(d));

        // validate new field names
        const invalidFieldNames = newFields.filter(d => !this.isValidFieldName(d));
        if (invalidFieldNames.length) {
            return { valid: false, invalidFieldNames };
        }

        // check fields that may not be private are not
        const mayNotBePrivate = [];
        for (const [fieldName, definition] of Object.entries(metadataByType)) {
            if (this.shouldNotBePrivate(fieldName) && definition.crypt) {
                mayNotBePrivate.push(fieldName);
            }
        }
        if (mayNotBePrivate.length) {
            return { valid: false, mayNotBePrivate };
        }

        return { valid: true };
    }

    /**
     * @param {string} eventId
     * @param {string} fpType
     * @param {Record<string,unknown} metadataByType
     */
    sanitizeMetadataUpdate(eventId, fpType, metadataByType) {
        const original = this.getCachedMetadataForType(eventId, fpType);
        const originalFields = Object.keys(original);
        const newFields = Object.keys(metadataByType).filter(d => !originalFields.includes(d));

        // set default value for new boolean fields
        for (const key of newFields) {
            const definition = metadataByType[key];
            if (definition.kind === 'boolean' && !definition.default) {
                metadataByType[key].default = false;
            }
        }

        // mark deleted fields as to be deleted
        for (const key of originalFields) {
            if (!metadataByType[key]) {
                metadataByType[key] = { toBeDeleted: true };
            }
        }

        return metadataByType;
    }

    /**
     * @param {string} eventId
     * @param {string} fpType
     * @param {Record<string,unknown} metadataByType
     * @param {Record<string,string>} representations
     */
    async saveFpTypeMetadata(eventId, fpType, metadataByType, representations) {
        const { valid, ...validationErrors } = this.validateMetadataUpdate(eventId, fpType, metadataByType);
        if (!valid) {
            return { updated: false, ...validationErrors };
        }

        const byType = this.sanitizeMetadataUpdate(eventId, fpType, metadataByType);
        const cachedMetadata = this.getCachedMetadata(eventId);

        // can't use allSettled until we switch to 2020. Can't use all because the bit part can fail.
        const metadata = await this.getMetadata(eventId, { forceUpdate: true });
        if (cachedMetadata._rev !== metadata._rev) {
            return { updated: false, metadataWasChanged: true };
        }

        // nothing to do in the catch, it's just that the bit doesn't exist
        const bit = await this.getMetadataBitByFpType(eventId, fpType).catch(() => {}) ?? {};

        const finalOverrideBit = this.getOverrideBitFromDiff(
            eventId,
            fpType,
            byType,
            metadata,
            bit,
            representations,
        );

        await this.saveMetadataBit(eventId, finalOverrideBit);

        // recompile metadtata and refresh cache
        const updatedMetadata = await this.getMetadata(eventId, { forceUpdate: true });

        return { updated: updatedMetadata[fpType] };
    }
}
